import { create } from 'zustand';
import fetch from 'node-fetch';

const useQuestionStore = create((set, get) => ({
    token: "",
    questions: [],
    getQuestions: async () => {
        let setToken = false;
        let token;
        if (get().token === ""){
            const tokenObj = await fetch('https://opentdb.com/api_token.php?command=request')
            token = await tokenObj.json()
            token = token.token
            setToken = true;
        } else {
            token = get().token
        }
        const questionsResponse = await fetch('https://opentdb.com/api.php?amount=10&encode=url3986&token=' + token)
        const questionsObj = await questionsResponse.json()
        const questions = questionsObj.results.map(q => { 
            q.hideAnswer = true; 
            q.hideOptions = true; 
            q.incorrect_answers.push(q.correct_answer); 
            let currentIndex = q.incorrect_answers.length,  randomIndex;

            // While there remain elements to shuffle.
            while (currentIndex != 0) {
        
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
        
            // And swap it with the current element.
            [q.incorrect_answers[currentIndex], q.incorrect_answers[randomIndex]] = [
                q.incorrect_answers[randomIndex], q.incorrect_answers[currentIndex]];
            }
            
            
            return q});
        
        if (setToken){
            set({token: token, questions: questions})
        } else {
            set({questions: questions})
        }
    }
}))

export default useQuestionStore;