import './App.css';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import useQuestionStore from './QuestionStore';
import QuestionDisplay from './QuestionDisplay';



function App() {
  const state = useQuestionStore()

  return (
  <Container className="p-3">
      <h1 className="header">Quiz? Here? Now?</h1>
      <Button onClick={() => state.getQuestions()}>Get Questions</Button>
      {state.questions.map((question,i) => {
        return <QuestionDisplay key={i} question={question}/>
      })}
  </Container>
  );
}

export default App;
