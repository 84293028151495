import React, {useState, useEffect } from "react"
import Button from 'react-bootstrap/Button';

const QuestionDisplay = (props) => {

    const [hide, toggleHide] = useState(props.question.hideAnswer)
    const [hideOptions, toggleHideOptions] = useState(props.question.hideOptions)
    
useEffect(() => {
    toggleHide(props.question.hideAnswer);
    toggleHideOptions(props.question.hideOptions);
}, [props])


    return (
        <div>
            <p>{props.question.type === 'boolean' && 'True or False - '} {decodeURIComponent(props.question.question)}</p>
            {hideOptions && props.question.type === 'multiple' && <p><Button onClick={() => toggleHideOptions(false)}>Show Options</Button></p>}
            {!hideOptions && props.question.incorrect_answers.map((ans, i) => <p key={i}>{decodeURIComponent(ans)}</p>)}
            {hide && <p><Button onClick={() => toggleHide(false)}>Show Answer</Button></p>}
            {!hide && <p><b>Correct Answer: </b>{decodeURIComponent(props.question.correct_answer)}</p>}
        </div>
    )
}    

export default QuestionDisplay